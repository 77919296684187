import { CustomField } from '@/pinia/payment/type'
import axios from '../instance'

interface ISmsParams {
  shopId: string;
  offset: number;
  limit: number;
}
  
interface customField {
	name: string;
  }

  interface ISmsList {
    pagination: {
        totals: number;
        pages: number;
    };
    data: SmsData[];
}

interface SmsData {
    _id: string;
    charLength: number;
    createdAt: Date;
    customField: customField;  // Replace 'any' with the appropriate type if known
    message: string;
    orderId: string;
    orderReference: string;
    phoneNo: string;
    shopId: string;
    smsFee: number;
    type: string;
    updatedAt: Date;
}

export const fetchLogSMS = async (
	params: ISmsParams
): Promise<ISmsList> => {
	try {
		const response = await axios.get(`/sms/${params.shopId}`, {
			params,
		})
		return {
			data: response.data.data,
			pagination: response.data.pagination
		}
	} catch (error) {
		return Promise.reject(error)
	}
}
